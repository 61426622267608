.detailed-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.detailed-image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.detailed-image {
  max-width: 100%;
  transition: transform 0.3s ease;
}

.zoom-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  flex: 1;
  margin: 2rem;
  padding: .5rem;
  max-width: 50rem;
  border-radius: 15px;
}

.zoom-controls button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;
  color: #333;
}

/* For tablets and small desktops */
@media (max-width: 992px) {
  .detailed-container {
    flex-direction: column;
  }

  .detailed-image-container,
  .info-container {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

/* For landscape phones and smaller tablets */
@media (max-width: 768px) {
  .zoom-controls {
    padding: 3px;
  }

  .zoom-controls button {
    font-size: 16px;
    margin: 3px;
  }

  .info-container {
    padding: 0.5rem;
  }
}

/* For portrait phones and smaller devices */
@media (max-width: 576px) {
  .zoom-controls button {
    font-size: 12px;
    margin: 2px;
  }

  .info-container {
    padding: 0.25rem;
  }
}
