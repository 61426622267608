/* src/Projects.css */
.project-card {
  border: 1px solid #ddd;
  background-color: #ffffff61;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 70rem;
}

.project-card h2 {
  font-size: 24px;
}

.project-card img {
  max-width: 100%;
  height: auto;
}

.project-card p {
  margin: 10px 0;
}

.project-card a {
  display: block;
  margin-top: 10px;
}

.center-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
}
