.main-header {
  display: flex;
  background-color: #ababac;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.home-nav-link {
  padding-left: 2rem;
}

.nav-link {
  font-family: 'Evil of Frankenstein', sans;
  text-decoration: none;
  color: inherit;
  font-size: 1.25rem;
  padding: 1rem;
}

.nav-link:hover {
  color: #412b6f;
}

.logo {
  height: 7rem;
}

.nav-mobile {
  display: none;
}

.hamburger {
  display: none;
  font-size: 3rem; 
  cursor: pointer;
}

.header-content {
  display: flex; /* Ensures horizontal alignment */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main-header {
    flex-direction: column;
    justify-content: flex-start; 
  }

  .header-content {
    flex-direction: row;
  }

  .contact-text {
    display: none; 
  }

  .nav {
    display: none;
  }

  .nav-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ababac;
    width: 100%;
  }

  .hamburger {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .logo {
    height: 5rem; /* Adjust the size if needed */
    padding: .5rem;
    margin: 0;
  }
  .home-nav-link {
    padding: 0;
  }
}