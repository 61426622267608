ul {
  list-style: none;
  padding: 0;
}
.contact-section {
  background-color: #ffffff8d;
  padding: .5rem;
  border-radius: 20px;
}

.contact-page {
  display: flex;
  flex-direction: column;
    align-items: center;
}

.contact-title {
  font-size: 3rem;
  margin-top: 2rem;
  padding:1rem;
}

.contact-li {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row; 
  align-items: center; 
}

.contact-text {
  font-family: 'Lexend Regular', sans-serif;
  margin-left: 15px; 
  font-weight: bolder;
  font-size: 2.5rem;   
  color: black;
}

.contact-links {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.contact-logo {
  font-size: 2.5rem;
  height: 7rem;
  padding: 1rem;
}

a {
  text-decoration: none;
}

.section-shape {
  display: flex;
  flex-direction: row;
}
/* Mobile devices */
@media (max-width: 600px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-text {
   display: none;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  .contact-title {
    font-size: 2.5rem;
  }

  .contact-text {
    font-size: 2rem;
  }
}

/* Small laptops and desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .contact-title {
    font-size: 2.75rem;
  }

  .contact-logo {
    height: 6rem;
  }

  .contact-text {
    font-size: 2.25rem;
  }
}

/* Large desktops */
@media (min-width: 1281px) {
  .contact-title {
    font-size: 3rem;
  }

  .contact-logo {
    height: 7rem;
  }

  .contact-text {
    font-size: 2.5rem;
  }
}
