.gallery-container {
  max-width: 800px;
  margin: 0 auto;
}

.gallery-title {
  text-align: center;
  font-size: 24px;
  padding: 1rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  grid-auto-flow: dense;
}

.image-container {
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff72;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.05);
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.image-title {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

/* For tablets and small desktops */
@media (max-width: 992px) {
  .image-grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  .gallery-title {
    font-size: 20px;
  }

  .image-title {
    font-size: 14px;
  }
}

/* For landscape phones and smaller tablets */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }

  .gallery-title {
    font-size: 18px;
    padding: 0.5rem;
  }

  .image-container {
    padding: 8px;
  }

  .image-title {
    font-size: 12px;
  }
}

/* For portrait phones and smaller devices */
@media (max-width: 576px) {
  .gallery-title {
    font-size: 16px;
    padding: 0.25rem;
  }

  .image-container {
    padding: 6px;
  }

  .image-title {
    font-size: 10px;
  }
}
