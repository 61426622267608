:root {
  font-size: 16px;
  --primary-color: #333333;
  --secondary-color: #555555;
  --link-color: #007BFF;
  --container-bg: #ffffff64;
  --container-padding: 2rem;
  --container-radius: 10px;
}

body {
  font-family: 'Lexend Regular', sans-serif;
  line-height: 1.6;
  background: #f4f4f4;
  margin: 0;
  color: var(--secondary-color);
}

h1, h2 {
  font-family: 'Evil of Frankenstein', sans-serif;
  color: var(--primary-color);
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.aboutme-container,
.aboutme-section {
  padding: var(--container-padding);
  margin: 20px;
  background: var(--container-bg);
  border-radius: var(--container-radius);
  position: relative;
}

.welcome-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.welcomepage-text-box {
  flex: 0 0 60%;
}

.ash-selfie {
  flex: 0 0 40%;
  width: 300px;
  height: auto;
}

.section-shape {
  padding: var(--container-padding);
  margin-bottom: var(--container-padding);
  background: var(--container-bg);
  border-radius: var(--container-radius);
}

.aboutme-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.aboutme-logo,
.camera-logo,
.emoji-logo {
  font-size: 2.5rem;
}

a {
  text-decoration: none;
  color: var(--link-color);
}



.welcomepage-text-box h2 {
  font-size: 28px;
  color: var(--primary-color);
  margin-bottom: 10px;
}
.welcomepage-text-box h3 {
  font-size: 20px;
  color: var(--primary-color);
  margin: 15px 0;
}

.welcomepage-text-box p {
  margin-bottom: 15px;
}

.ash-selfie {
  max-width: 24rem;
  height: auto;
  border-radius: var(--container-radius);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* For tablets and small desktops */
@media (max-width: 992px) {
  .welcome-container {
    flex-direction: column;
  }
  
  .welcomepage-text-box,
  .ash-selfie {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0;
  }

  .ash-selfie {
    width: 100%;
  }
}

/* For landscape phones and smaller tablets */
@media (max-width: 768px) {
  :root {
    --container-padding: 1rem;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }
}

/* For portrait phones and smaller devices */
@media (max-width: 576px) {
  :root {
    --container-padding: 0.5rem;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }
  
  .aboutme-links {
    flex-direction: column;
  }
  
  .aboutme-logo,
  .camera-logo,
  .emoji-logo {
    font-size: 2rem;
  }
}
