* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --bg: #e3e4e8;
  --fg: #17181c;
  --dur: 1s; 
}

.camera-lens {
  background: var(--bg);
  color: var(--fg);
  font: bold 1em/1.5 "Comfortaa", sans-serif;
  display: grid;
  place-items: center;
  align-content: center;
  height: 100vh;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.spinning {
  width: 10rem; 
  height: 10rem; 
}

.circle {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: black; 
  animation: expandAndContract var(--dur) infinite alternate ease-in-out; /* Animation */
}

@keyframes expandAndContract {
  from {
    transform: scale(1); /* Initial size */
  }
  to {
    transform: scale(1.5); /* Expanded size (adjust as needed) */
  }
}
