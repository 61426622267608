.gallery-container {
  max-width: 800px;
  margin: 0 auto;
}

.gallery-title {
  text-align: center;
  font-size: 24px;
  padding: 1rem;
}

.art-image-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  grid-auto-flow: dense;
}

.art-image-container {
  height:fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.art-image-container:hover {
  transform: scale(1.05);
}

.gallery-image {
  width: 100%;
  height: 100%; /* The image will take up the full height of its container */
  object-fit: contain; /* The image will scale to fit within the container without cropping or distortion */
  border-radius: 10px;
}

.image-title {
  font-family: 'Lexend Regular', sans-serif;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

/* For tablets and small desktops */
@media (max-width: 992px) {
  .art-image-grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  
  .gallery-title {
    font-size: 20px;
  }

  .image-title {
    font-size: 14px;
  }
}

/* For landscape phones and smaller tablets */
@media (max-width: 768px) {
  .art-image-grid {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }

  .gallery-title {
    font-size: 18px;
    padding: 0.5rem;
  }

  .art-image-container {
    padding: 8px;
  }

  .image-title {
    font-size: 12px;
  }
}
